// Wird auf ALLEN seiten geladen
import {Carousel} from 'bootstrap';

Array.from(document.querySelectorAll('.rott-gallery'))
    .forEach(n => new Carousel(n))


// Fetch all the forms we want to apply custom Bootstrap validation styles to
const forms = document.querySelectorAll('.needs-validation')

// Loop over them and prevent submission
Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
        //form.check
        if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
        }

        form.classList.add('was-validated')
    }, false)
})
